import { UserIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { Student } from '../routes/Students';

const items = [
  { title: 'Total Courses Enrolled', value: '13' },
  { title: 'Courses in Progress', value: '3' },
  { title: 'Completed Courses', value: '4' },
  { title: 'Total Content Watched', value: '13h 4m' },
];

const Card: React.FC<{ title: string; value: string }> = ({ title, value }) => {
  return (
    <div className='h-[100px] w-full lg:w-[120px] mb-2 lg:mb-0 border-[1px] p-2 border-slate-200 rounded-md flex flex-col items-center justify-between'>
      <h4 className='text-sm text-center text-slate-500 font-semibold'>
        {title}
      </h4>
      <h2 className='text-2xl font-semibold text-[#1C1919]'>{value}</h2>
    </div>
  );
};

export const ViewStudent: React.FC<{ data: Student; closeModal: any }> = ({
  data,
  closeModal,
}) => {
  return (
    <div className='bg-white absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 w-[90%] lg:w-[600px] min-h-[300px] shadow-[24px] p-4 rounded-lg'>
      <button
        onClick={closeModal}
        className='shadow-all-sides absolute -top-4 -right-4 z-50 bg-white p-2 rounded-full text-gray-500 hover:text-gray-700 cursor-pointer'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-5 w-5'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M6 18L18 6M6 6l12 12'
          />
        </svg>
      </button>
      <div className='flex h-[80px]'>
        <div className='h-[80px] w-[80px] bg-slate-300 rounded-[40px] flex items-center justify-center'>
          <UserIcon color='#fff' className='h-[50px]' />
        </div>
        <div className='h-full flex flex-col justify-between py-2 ml-2 text-[#1C1919]'>
          <h3 className='font-semibold text-xl'>
            {data.first_name} {data.last_name}
          </h3>
          <h4 className='text-sm'>{data.email}</h4>
        </div>
      </div>

      <div className='mt-8 flex flex-col lg:flex-row justify-around'>
        {items.map(({ title, value }) => (
          <Card title={title} value={value} key={title} />
        ))}
      </div>
    </div>
  );
};
