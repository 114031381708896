//https://tanstack.com/table/latest/docs/framework/react/examples/row-selection

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import React, { HTMLProps, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ErrorProps } from '../../../../types';
import { notifyError, notifySuccess } from '../../../../utils/notifications';
import { Loader } from '../../../liveworkshops/components/Loader';
import { useGetLiveSessionStudents } from '../../students/api';
import { useGenerateCertificate } from '../api/generateCertificate';
import { useGetLiveSession } from '../api/getLiveSession';

export const RegisteredStudents = () => {
  const [rowSelection, setRowSelection] = React.useState({});

  const { liveSessionId } = useParams();
  const { data: liveSession, isFetching: isFetchingLiveSession } =
    useGetLiveSession(liveSessionId as string);

  const { data: students, isFetching: isFetchingStudents } =
    useGetLiveSessionStudents(liveSessionId as string);

  const columns = useMemo<
    ColumnDef<{
      full_name: string;
      email: string;
      registration_date: string;
    }>[]
  >(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className=''>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        header: 'Full Name',
        accessorKey: 'full_name',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Registration Date',
        accessorKey: 'registration_date',
      },
    ],
    [],
  );

  const table = useReactTable({
    data: students,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    getRowId: (row) => row.email,
  });

  const {
    isPending,
    mutate: generateCertificate,
    isSuccess,
    isError,
    error,
    reset,
  } = useGenerateCertificate();

  useEffect(() => {
    if (isSuccess) {
      notifySuccess(
        'Certificates have been generated for selected students',
        false,
      );
      reset();
    }

    if (isError) {
      notifyError(
        (error as unknown as ErrorProps).response?.data?.data?.detail ||
          'An error occured. Please try again.',
        false,
      );
      reset();
    }
  }, [isSuccess, isError]);

  const handleGenerateCertificate = () => {
    const selectedRows = table.getState().rowSelection;
    const studentsEmails = Object.keys(selectedRows);

    console.log('Selected Students:', studentsEmails);
    generateCertificate({
      emails: studentsEmails,
      live_session_id: liveSessionId!,
    });
  };

  if (isFetchingStudents || isFetchingLiveSession) return <p>Loading...</p>;

  return (
    <div className='p-2'>
      <h2 className='flex mr-2 font-semibold text-md sm:text-lg text-indigo mt-2'>
        Live Sessions/ Students/{' '}
        {liveSession ? liveSession.slug : liveSessionId}
      </h2>

      <div className='flex justify-end mb-4'>
        <button
          disabled={Object.keys(table.getState().rowSelection).length === 0}
          onClick={handleGenerateCertificate}
          className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-secondary disabled:bg-gray-300 disabled:cursor-not-allowed'
        >
          Generate Certificate
        </button>
      </div>

      <div className='h-2' />
      <table className='min-w-full divide-y-[1px] divide-slate-200 text-slate-700 sm:rounded-lg'>
        <thead className='bg-[#E8F0FF]'>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className='px-2 py-2 text-left text-[14px] font-bold text-slate-600 rounded-sm'
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className='bg-white divide-y-[1px] divide-slate-200 text-[14px]'>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className='px-2 py-2 whitespace-nowrap text-slate-500'
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot className=''>
          <tr>
            <td className='p-2'>
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllPageRowsSelected(),
                  indeterminate: table.getIsSomePageRowsSelected(),
                  onChange: table.getToggleAllPageRowsSelectedHandler(),
                }}
              />
            </td>
            <td colSpan={20}>Page Rows ({table.getRowModel().rows.length})</td>
          </tr>
        </tfoot>
      </table>
      <div className='h-2' />
      <div className='flex items-center gap-2'>
        <button
          className='border rounded p-1'
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className='border rounded p-1'
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className='border rounded p-1'
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className='border rounded p-1'
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className='flex items-center gap-1'>
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className='flex items-center gap-1'>
          | Go to page:
          <input
            type='number'
            min='1'
            max={table.getPageCount()}
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className='border p-1 rounded w-16'
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div>
        {Object.keys(rowSelection).length} of{' '}
        {table.getPreFilteredRowModel().rows.length} Total Rows Selected
      </div>
      <hr />
      <br />

      <ToastContainer />

      <Loader isLoading={isPending} />
    </div>
  );
};

function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  return typeof firstValue === 'number' ? (
    <div className='flex space-x-2'>
      <input
        type='number'
        value={((column.getFilterValue() as any)?.[0] ?? '') as string}
        onChange={(e) =>
          column.setFilterValue((old: any) => [e.target.value, old?.[1]])
        }
        placeholder={'Min'}
        className='w-24 border shadow rounded'
      />
      <input
        type='number'
        value={((column.getFilterValue() as any)?.[1] ?? '') as string}
        onChange={(e) =>
          column.setFilterValue((old: any) => [old?.[0], e.target.value])
        }
        placeholder={'Max'}
        className='w-24 border shadow rounded'
      />
    </div>
  ) : (
    <input
      type='text'
      value={(column.getFilterValue() ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={'Search...'}
      className=' px-2  border shadow rounded w-[80%] my-1'
    />
  );
}

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type='checkbox'
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  );
}
