import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';
import { ErrorProps } from '../../../../types';
import { notifyError, notifySuccess } from '../../../../utils/notifications';
import { LiveSessionProps } from '../../../liveworkshops/types';
import { LiveSessionResp } from '../types';

const startLiveSession = async (liveSessionID: string) => {
  const resp = await axios.patch(`/sessions/live/${liveSessionID}/`, {
    status: 'ongoing',
  });
  return resp.data;
};

export const useStartLiveSession = () => {
  const queryClient = useQueryClient();
  const upcomingSessions: LiveSessionResp | undefined =
    queryClient.getQueryData(['upcoming-live-sessions']);

  const ongoingLiveSessions: LiveSessionResp | undefined =
    queryClient.getQueryData(['ongoing-live-sessions']);

  return useMutation({
    mutationFn: startLiveSession,
    onSuccess: (data) => {
      try {
        const updatedUpcomingSessions = {
          ...upcomingSessions,
          data: {
            count: Number(upcomingSessions?.data?.count) - 1,
            results: upcomingSessions?.data.results.filter(
              (upcomingSession: LiveSessionProps) =>
                data.data.id !== upcomingSession.id,
            ),
          },
        };

        const updatedOngoingSessions = {
          ...ongoingLiveSessions,
          data: {
            count: Number(ongoingLiveSessions?.data?.count) + 1,
            results: ongoingLiveSessions
              ? [data.data, ...ongoingLiveSessions.data.results]
              : [data.data],
          },
        };

        queryClient.setQueryData(
          ['upcoming-live-sessions'],
          updatedUpcomingSessions,
        );

        queryClient.setQueryData(
          ['ongoing-live-sessions'],
          updatedOngoingSessions,
        );

        notifySuccess('Live Session started successfully', 3000);
      } catch (error) {
        console.log(error);
        notifyError('Something went wrong. Please try again', false);
      }
    },
    onError: (error: ErrorProps) => {
      notifyError(
        error.response?.data.message ??
          (error.message || 'Something went wrong. Please try again'),
        false,
      );
    },
  });
};
export const useStartAllLiveSession = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: startLiveSession,
    onSuccess: (data) => {
      queryClient.setQueryData(['all_live_sessions'], (oldQueryData: any) => {
        const results = [
          data.data,
          ...oldQueryData.data.results.filter(
            (session: LiveSessionProps) => session.id !== data.data.id,
          ),
        ];

        return {
          ...oldQueryData,
          data: {
            count: oldQueryData.data.count,
            results,
          },
        };
      });
    },
    onError: (error: ErrorProps) => {
      notifyError(
        error.response?.data.message ??
          (error.message || 'Something went wrong. Please try again'),
        false,
      );
    },
  });
};
