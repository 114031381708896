import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import {
  XMarkIcon,
  Bars3BottomLeftIcon as MenuAltIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';

import { logoLight, signOutIcon, userIcon } from '../../assets';
import { adminPortalLinks } from '../../constants/navlinks';
import { User } from '../../features/auth';
import { formatTitle } from '../../utils/converter';
import storage from '../../utils/storage';
import { Link } from '../Elements';

type AdminLayoutProps = {
  children: React.ReactNode;
};

type SidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Logo = () => (
  <div className='flex items-center'>
    <Link to='/'>
      <img src={logoLight} alt='logo' className='main-logo w-[128px]' />
    </Link>
  </div>
);

const SideNavigation: React.FC<{ closeNav?: () => void }> = ({ closeNav }) => {
  const location = useLocation();
  const path = location.pathname;
  const loggedInUser: User = storage.getUser();
  const navigate = useNavigate();

  useEffect(() => {
    const path_index = adminPortalLinks.findIndex(
      (nav) => nav.pathname === path,
    );

    setActiveNav(path_index);
  }, [path]);

  const [activeNav, setActiveNav] = useState(0);

  const handleLogout = () => {
    storage.clearToken();
    storage.clearUser();
    navigate('/');
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        {adminPortalLinks.map((item, index) => (
          <div
            key={item.title}
            className={`${
              index === activeNav ? 'bg-[#170E88]' : ''
            } mx-2 ps-6 py-3 hover:bg-[#170E88]`}
          >
            <NavLink
              end={index === activeNav}
              to={item.pathname}
              className={clsx('flex items-center', 'text-[15px]', 'text-white')}
              onClick={() => {
                setActiveNav(index);
                closeNav && closeNav();
              }}
            >
              <img
                src={item.icon}
                alt='icon'
                className={clsx('mr-2 flex-shrink-0 w-4')}
                // aria-hidden='true'
              />

              {item.title}
            </NavLink>
          </div>
        ))}
      </div>

      <div className='text-white mb-4 flex mx-2 h-10'>
        <div className='relative flex-col flex-[0.18]'>
          <div className='rounded-full border-[1px] h-8 w-8 border-[#ffffff] p-2 flex items-center'>
            <UserIcon className='w-[16px]  text-white' />
          </div>
        </div>
        <div className='flex flex-col flex-[0.77] pl-[5px]'>
          <span className='text-sm'>
            {`${loggedInUser.first_name} ${loggedInUser.last_name}`}
          </span>
          <span className='text-sm font-thin text-[#E3EFFC]'>
            {loggedInUser.email.length > 27
              ? loggedInUser.email.substring(0, 27) + '...'
              : loggedInUser.email}
          </span>
          <span className='text-sm font-thin text-[#E3EFFC]'>admin</span>
        </div>
        <div className='flex flex-[0.15] justify-end'>
          <button
            onClick={handleLogout}
            className='self-center hover:bg-slate-600 p-1 rounded'
          >
            <img className='h-4 w-4' src={signOutIcon} alt='sign out icon' />
          </button>
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => (
  <div className='hidden lg:flex lg:flex-shrink-0 bg-secondary w-[280px]'>
    <div className='flex flex-col w-[100%]'>
      <div className='flex flex-col flex-1 h-0'>
        <div className='flex items-center h-20 flex-shrink-0 px-4  header-box-shadow border-b-[0.9px] border-[#5A59A4]'>
          <Logo />
        </div>
        <div className='flex flex-col flex-1 overflow-y-auto'>
          <nav className='flex-1 py-2 mt-2 space-y-1 bg-dimWhite-100'>
            <SideNavigation />
          </nav>
        </div>
      </div>
    </div>
  </div>
);

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => (
  <Transition show={sidebarOpen} as={Fragment}>
    <Dialog
      as='div'
      static
      className='fixed inset-0 z-40 flex lg:hidden'
      open={sidebarOpen}
      onClose={setSidebarOpen}
    >
      <TransitionChild
        as={React.Fragment}
        enter='transition ease-in-out duration-300 transform'
        enterFrom='-translate-x-full'
        enterTo='translate-x-0'
        leave='transition ease-in-out duration-300 transform'
        leaveFrom='translate-x-0'
        leaveTo='-translate-x-full'
      >
        <div className='relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-secondary'>
          <TransitionChild
            as={React.Fragment}
            enter='ease-in-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='absolute top-0 right-0 pt-2 -mr-12'>
              <button
                className='flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                onClick={() => setSidebarOpen(false)}
              >
                <span className='sr-only'>Close side navigation</span>
                <XMarkIcon className='w-6 h-6 text-white' aria-hidden='true' />
                {/* <img src={close} alt='xlose menu' /> */}
              </button>
            </div>
          </TransitionChild>
          <div className='flex items-center flex-shrink-0 px-4'>
            <Logo />
          </div>
          <div className='flex-1  mt-5 overflow-y-auto'>
            <nav className='px-2 space-y-1 h-full'>
              <SideNavigation closeNav={() => setSidebarOpen(false)} />
            </nav>
          </div>
        </div>
      </TransitionChild>
      <div className='flex-shrink-0 w-14' aria-hidden='true'></div>
    </Dialog>
  </Transition>
);

const MobileNavbar: React.FC<{ setSidebarOpen: (val: boolean) => void }> = ({
  setSidebarOpen,
}) => (
  <div className='relative z-10 flex flex-shrink-0 min-h-16 hr-shadow bg-secondary lg:hidden'>
    <button
      className='px-4 text-white border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary-500 lg:hidden'
      onClick={() => setSidebarOpen(true)}
    >
      <span className='sr-only'>Open side navigation</span>
      <MenuAltIcon color='#ffffff' className='w-6 h-6 ' aria-hidden='true' />
    </button>
    <div className='flex items-center justify-between w-full ml-2 lg:hidden '>
      <Logo />
    </div>
  </div>
);

export const AdminLayout = ({ children }: AdminLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const path_name = location.pathname.split('/admin/')[1] ?? 'dashboard';

  // Check if the pathname matches the edit live session url format
  const editLiveSessionMatches = location.pathname.match(
    /^\/admin\/live-sessions\/edit\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/,
  );

  // Check if the pathname matches the view live session url format
  const viewLiveSessionMatches = location.pathname.match(
    /^\/admin\/live-sessions\/view\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/,
  );

  const studentsLiveSessionMatches = location.pathname.match(
    /^\/admin\/live-sessions\/students\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/,
  );

  const showTitle =
    !editLiveSessionMatches &&
    !viewLiveSessionMatches &&
    !studentsLiveSessionMatches;

  return (
    <div className='flex h-screen overflow-hidden'>
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Sidebar />

      <div className='flex flex-col flex-1 w-0 overflow-hidden lg:ml-4'>
        <MobileNavbar setSidebarOpen={setSidebarOpen} />
        <main className='relative flex-1 px-4 pb-4 overflow-auto focus:outline-none md:py-4'>
          {showTitle && (
            <h2 className='flex mr-2 font-semibold text-md sm:text-lg text-indigo mt-2 mb-6'>
              {formatTitle(path_name)}
            </h2>
          )}

          {children}
        </main>
      </div>
    </div>
  );
};
