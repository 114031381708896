import { useMutation, useQuery } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';

type LiveSessionRegistration = {
  id: string;
  payment_status: string;
  registration_date: string;
  user: { id: string; full_name: string };
};

const getStudents = async () => {
  const response = await axios.get('/adminapp/students/');
  return response.data;
};

const getLiveSessionStudents = async (liveSessionID: string) => {
  const response = await axios.get(
    `/liveregister/students?live_session_id=${liveSessionID}&sort=asc`,
  );
  return response.data;
};

export const getLiveSessions = async () => {
  const response = await axios.get('/sessions/live');

  return response.data;
};

export const registerStudentForCourse = async (payload: {
  email: string;
  live_session_id: string;
}) => {
  const response = await axios.post('/liveregister/manual-register/', payload);

  return response.data;
};

export const unregisterStudentFromCourse = async (payload: {
  email: string;
  live_session_id: string;
}) => {
  const response = await axios.delete('/liveregister/unregister/', {
    data: payload,
  });

  return response.data;
};

export const useGetStudents = () => {
  return useQuery({
    queryKey: ['students'],
    queryFn: getStudents,
    select: (data) => data?.data?.students,
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const useGetLiveSessions = () => {
  return useQuery({
    queryFn: getLiveSessions,
    queryKey: ['live-sessions'],
    staleTime: 24 * 60 * 60 * 1000,
    select: (data) => {
      return data?.data; //?.results;
    },
  });
};

export const useRegisterStudentForCourse = () => {
  return useMutation({
    mutationFn: registerStudentForCourse,
  });
};

export const useUnregisterStudentFromCourse = () => {
  return useMutation({
    mutationFn: unregisterStudentFromCourse,
  });
};

export const useGetLiveSessionStudents = (liveSessionID: string) => {
  return useQuery({
    queryKey: [`students-${liveSessionID}`],
    queryFn: () => getLiveSessionStudents(liveSessionID),
    select: (data) => {
      return data?.data?.map((reg: LiveSessionRegistration) => ({
        ...reg.user,
        registration_date: new Date(reg.registration_date).toDateString(),
      }));
    },
    staleTime: 24 * 60 * 60 * 1000,
  });
};
