import { useMutation } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';

export const generateCertificate = async (payload: {
  emails: string[];
  live_session_id: string;
}) => {
  const response = await axios.post('/sessions/generate-certificate/', payload);

  return response.data;
};

export const useGenerateCertificate = () => {
  return useMutation({
    mutationFn: generateCertificate,
  });
};
