import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';
import styles from '../../utils/styles';

const options = [
  { value: '', label: '' },
  { value: '1 month', label: '1 Month' },
  { value: '3 months', label: '3 Months' },
  { value: '6 months', label: '6 Months' },
  { value: '1 year', label: '1 Year' },
  { value: 'perpetual', label: 'Perpetual' },
  { value: 'custom', label: 'Custom Date' },
];

interface DateSelectProps {
  onChange: (date: string) => void;
  defaultDate?: string; // Optional default date (YYYY-MM-DD format)
  isDisabled?: boolean;
}

export const DateSelect: React.FC<DateSelectProps> = ({
  onChange,
  defaultDate,
  isDisabled,
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [customDate, setCustomDate] = useState<Date | null>(null);

  useEffect(() => {
    if (defaultDate) {
      const parsedDate = DateTime.fromISO(defaultDate, { zone: 'utc' });

      if (!parsedDate.isValid) return;

      // Determine if default date matches any predefined options
      const predefinedOption = options.find((opt) => {
        const dd = getCalculatedDate(opt.value);
        const val = parsedDate.toFormat('yyyy-MM-dd') === dd;

        return val;
      });

      if (predefinedOption) {
        setSelectedOption(predefinedOption);
        onChange(parsedDate.toFormat('yyyy-MM-dd'));
      } else {
        setSelectedOption({ value: 'custom', label: 'Custom Date' });
        setCustomDate(parsedDate.toJSDate());
        onChange(parsedDate.toFormat('yyyy-MM-dd'));
      }
    }
  }, [defaultDate, onChange]);

  const getCalculatedDate = (option: string, selectedDate?: Date) => {
    let targetDate: DateTime;

    if (option === 'custom' && selectedDate) {
      targetDate = DateTime.fromJSDate(selectedDate).toUTC();
    } else {
      const today = DateTime.utc();

      switch (option) {
        case '1 month':
          targetDate = today.plus({ months: 1 });
          break;
        case '3 months':
          targetDate = today.plus({ months: 3 });
          break;
        case '6 months':
          targetDate = today.plus({ months: 6 });
          break;
        case '1 year':
          targetDate = today.plus({ years: 1 });
          break;
        case 'perpetual':
          targetDate = today.plus({ years: 100 });
          break;

        default:
          return '';
      }
    }

    return targetDate.toFormat('yyyy-MM-dd');
  };

  const handleChange = (selected: any) => {
    setSelectedOption(selected);
    if (selected.value !== 'custom') {
      setCustomDate(null);

      const date = getCalculatedDate(selected.value);

      onChange(date);
    }
  };

  const handleCustomDate = (date: Date | null) => {
    setCustomDate(date);

    if (date) {
      const formattedDate = getCalculatedDate('custom', date);
      onChange(formattedDate);
    }
  };

  return (
    <div>
      <Select
        options={options}
        value={selectedOption}
        onChange={handleChange}
        isDisabled={isDisabled}
      />

      {selectedOption.value === 'custom' && (
        <div style={{ marginTop: '10px' }}>
          <label className={styles.label} htmlFor='date'>
            Select Custom Date:
          </label>
          <DatePicker
            selected={customDate}
            onChange={handleCustomDate}
            dateFormat='MMMM d, yyyy'
            //minDate={new Date()} // Prevent past dates
            className={styles.input}
            disabled={isDisabled}
          />
        </div>
      )}
    </div>
  );
};
