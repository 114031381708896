import { useQuery } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';
import storage from '../../../../utils/storage';

const getLiveSession = async (id: string) => {
  const user = storage.getUser();
  const resp = await axios.get(
    `/sessions/live/${id}?include_expired=${Boolean(user?.is_staff)}`,
  );
  const liveSession = resp.data;
  return liveSession;
};
const getAllLiveSessions = async () => {
  const resp = await axios.get(`sessions/live/?include_expired=true`);
  const allLiveSessions = resp.data;
  return allLiveSessions;
};

const getLiveSessionWithCoursePopulated = async (id: string) => {
  const liveSession = await getLiveSession(id);

  if (liveSession && liveSession.data?.id) {
    const course = await getCourseDetails(liveSession.data.course_id);

    if (course) {
      liveSession.data['sections'] = course.sections;
    }
  }

  return liveSession;
};

const getCategories = async () => {
  const resp = await axios.get('/sessions/category');
  return resp.data;
};

export const getCourseDetails = async (courseID: string) => {
  const user = storage.getUser();
  const resp = await axios.get(
    `/sessions/courses/${courseID}/?include_expired=${Boolean(user?.is_staff)}`,
  );
  return resp.data;
};

const getOngoingLiveSessions = async () => {
  const user = storage.getUser();
  const response = await axios.get(
    `/sessions/live/?status=ongoing&include_expired=${Boolean(user?.is_staff)}`,
  );

  return response.data;
};

export const getSectionDetails = async (sectionID: string) => {
  const resp = await axios.get(`/sessions/sections/${sectionID}/`);
  return resp.data;
};

export const useGetOngoingLiveSessions = () => {
  return useQuery({
    queryFn: getOngoingLiveSessions,
    queryKey: ['ongoing-live-sessions'],
    staleTime: 24 * 60 * 60 * 1000,
    select: (data) => {
      return data?.data?.results;
    },
  });
};

export const useGetLiveSession = (id: string) => {
  return useQuery({
    queryKey: [`live-session_${id}`],
    queryFn: () => getLiveSession(id),
    select: (data) => {
      return { ...data?.data, image_id: data?.data.image };
    },
    staleTime: 24 * 60 * 60 * 1000,
  });
};
export const useGetAllLiveSessions = () => {
  return useQuery({
    queryKey: [`all_live_sessions`],
    queryFn: getAllLiveSessions,
    select: (data) => {
      return data?.data?.results;
    },
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const useGetLiveSessionWithCoursePopulated = (id: string) => {
  return useQuery({
    queryKey: [`live-session_${id}`],
    queryFn: () => getLiveSessionWithCoursePopulated(id),
    select: (data) => {
      return { ...data?.data, image_id: data?.data.image };
    },
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const useGetCategories = () => {
  return useQuery({
    queryFn: getCategories,
    queryKey: ['categories'],
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const useGetSectionDetails = (id: string) => {
  return useQuery({
    queryKey: [`live-session-section_${id}`],
    queryFn: () => getSectionDetails(id),
  });
};
