import { Menu, Transition } from '@headlessui/react';
import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
  DocumentMagnifyingGlassIcon,
  CheckCircleIcon,
  PlayIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState, Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { notifyError, notifySuccess } from '../../../../utils/notifications';
import { Loader } from '../../../liveworkshops/components/Loader';
import { useGetAllLiveSessions } from '../api/getLiveSession';
import { useStartAllLiveSession } from '../api/startLiveSession';

// Define the LiveSession type
type LiveSession = {
  _id: string;
  title: string;
  price: number;
  discount: number;
  studentsCount: number;
  startDate: string;
  registrationDueDate: string;
  status: string;
};

export const AllLiveSessions = () => {
  const { data: sessions, isFetching } = useGetAllLiveSessions();
  const {
    mutate: startLiveSession,
    isPending,
    isSuccess,
    isError,
  } = useStartAllLiveSession();
  const navigate = useNavigate();
  console.log('Sessions: data', sessions);

  useEffect(() => {
    if (isSuccess) {
      notifySuccess('Started live session succcessfully', false);
    }

    if (isError) {
      notifyError('An error has occured.', false);
    }
  }, [isSuccess, isError]);

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Slug',
        accessor: 'slug', //  Added Slug column
      },
      {
        Header: 'Price (USD)',
        accessor: 'price_usd',
      },
      //{
      /*
        Header: 'Discount',
        Cell: ({ row }: any) => {
          return `${row.original.discount_percentage}`;
        },
      */
      //},

      {
        Header: 'No. of Students',
        accessor: 'no_of_students',
      },
      //{
      //Header: 'Start Date',
      // accessor: 'start_date',
      //},
      //{
      // Header: 'Reg. Due Date',
      // accessor: 'registration_due_date',
      //},
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Action',
        id: 'menu',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div>
            <SessionActions
              _id={row.original.id}
              startLiveSession={startLiveSession}
            />
          </div>
        ),
      },
    ],
    [startLiveSession],
  );

  if (isFetching) return <p>Loading.... Please Wait!!!</p>;

  return (
    <div className='p-4 bg-white shadow-md rounded-lg'>
      <div className='overflow-x-auto'>
        <table className='w-full border-collapse border border-gray-200'>
          <thead>
            <tr className='bg-gray-100'>
              {columns.map((column) => (
                <th
                  key={column.Header}
                  className='border border-gray-300 px-4 py-2 text-left'
                >
                  {column.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sessions?.map((session: LiveSession) => (
              <tr key={session._id} className='border border-gray-300'>
                {columns.map((column) => (
                  <td
                    key={column.Header}
                    className='border border-gray-300 px-4 py-2'
                  >
                    {column.Cell
                      ? column.Cell({ row: { original: session } })
                      : session[column.accessor as keyof LiveSession]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isPending && <Loader isLoading={isPending} />}
      <ToastContainer />
    </div>
  );
};

// Action menu component
type SessionActionsProps = {
  _id: string;
  startLiveSession: any;
};

const SessionActions = ({ _id, startLiveSession }: SessionActionsProps) => {
  const navigate = useNavigate();
  const handleView = () => navigate(`/admin/live-sessions/view/${_id}`);
  const handleEdit = () => navigate(`/admin/live-sessions/edit/${_id}`);
  const handleStart = () => {
    startLiveSession(_id);
  };
  const handleComplete = () => console.log('Complete session:', _id);

  return (
    <div className='relative'>
      <Menu as='div' className='inline-block text-left'>
        <div>
          <Menu.Button className='flex items-center justify-center w-8 h-8 bg-white shadow-md'>
            <EllipsisVerticalIcon className='h-5' />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 z-10 mt-2 w-36 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5'>
            <div className='px-1 py-1'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleView}
                    className={`${active ? 'text-primary' : 'text-black'} flex w-full items-center px-2 py-2 text-sm`}
                  >
                    <DocumentMagnifyingGlassIcon className='w-4 h-4 mr-2' />
                    View
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleEdit}
                    className={`${active ? 'text-primary' : 'text-black'} flex w-full items-center px-2 py-2 text-sm`}
                  >
                    <PencilSquareIcon className='w-4 h-4 mr-2' />
                    Edit
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleStart}
                    className={`${active ? 'text-primary' : 'text-black'} flex w-full items-center px-2 py-2 text-sm`}
                  >
                    <PlayIcon className='w-4 h-4 mr-2' />
                    Start
                  </button>
                )}
              </Menu.Item>
              {/*<Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleComplete}
                    className={`${active ? 'text-primary' : 'text-black'} flex w-full items-center px-2 py-2 text-sm`}
                  >
                    <CheckCircleIcon className='w-4 h-4 mr-2' />
                    Complete
                  </button>
                )}
              </Menu.Item>
              */}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
