import { DateTime } from 'luxon';

export const formatDate = (dateString: string) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const date = new Date(dateString);

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const formattedCSTDate = `${day} ${months[monthIndex]}, ${year}`;
  return formattedCSTDate;
};

type ConvertedTime = {
  cstTime: string;
  watTime: string;
} | null;

export const convertDateTime = (
  startDate: string,
  startTime: string,
): ConvertedTime => {
  try {
    // Combine start date and time into a single DateTime object in UTC
    const combinedDateTime = DateTime.fromISO(`${startDate}T${startTime}`, {
      zone: 'UTC',
    });

    const cstTime = combinedDateTime.setZone('America/Chicago');
    const watTime = combinedDateTime.setZone('Africa/Lagos');

    return {
      cstTime: cstTime.toFormat('MMM d yyyy, h:mma'), // e.g., "Feb 8 2025, 5:00PM"
      watTime: watTime.toFormat('MMM d yyyy, h:mma'), // e.g., "Feb 9 2025, 12:00AM"
    };
  } catch (error: any) {
    console.error('Error in convertTime:', error.message);
    return null;
  }
};

// export const getClassDaysInCST = (
//   startDateGMT: string,
//   startTimeGMT: string,
//   classDaysGMT: string[],
// ) => {
//   const daysOfTheWeek = [
//     'sunday',
//     'monday',
//     'tuesday',
//     'wednesday',
//     'thursday',
//     'friday',
//     'saturday',
//   ];
//   const combinedDateTime = DateTime.fromISO(`${startDateGMT}T${startTimeGMT}`, {
//     zone: 'UTC',
//   });

//   const cstTime = combinedDateTime.setZone('America/Chicago');

//   const startDayGMT = parseInt(startDateGMT.split('-')[2]);
//   const startDayCST = cstTime.day;

//   if (startDayCST < startDayGMT) {
//     const classDaysCST = [];
//     const gmtClassDaysIndeces = classDaysGMT.map((day) =>
//       daysOfTheWeek.indexOf(day),
//     );

//     for (let i = 0; i < gmtClassDaysIndeces.length; i++) {
//       let offset;
//       if (gmtClassDaysIndeces[i] === 0) {
//         offset = 6;
//       } else {
//         offset = gmtClassDaysIndeces[i] - 1;
//       }
//       classDaysCST.push(daysOfTheWeek[offset]);
//     }
//     console.log({ classDaysGMT, classDaysCST });
//     return classDaysCST;
//   }

//   return classDaysGMT;
// };

export const getClassDaysInCST = (
  startDateGMT: string,
  startTimeGMT: string,
  classDaysGMT: string[],
) => {
  const daysOfTheWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  // Convert start date and time to CST
  const combinedDateTime = DateTime.fromISO(`${startDateGMT}T${startTimeGMT}`, {
    zone: 'UTC',
  }).setZone('America/Chicago');

  // Find the day of the week in both GMT and CST
  const gmtStartDayIndex =
    DateTime.fromISO(`${startDateGMT}T00:00`, {
      zone: 'UTC',
    }).weekday % 7; // Luxon uses 1-7 (Monday-Sunday), so we mod by 7 to match 0-6

  const cstStartDayIndex = combinedDateTime.weekday % 7;

  // Calculate the day shift
  const dayShift = cstStartDayIndex - gmtStartDayIndex;

  // Convert class days to CST
  const classDaysCST = classDaysGMT.map((day) => {
    const gmtIndex = daysOfTheWeek.indexOf(day.toLowerCase());
    if (gmtIndex === -1) return day; // Fallback in case of an unexpected value

    // Adjust the day index based on the actual time zone shift
    const cstIndex = (gmtIndex + dayShift + 7) % 7;
    return daysOfTheWeek[cstIndex];
  });

  return classDaysCST;
};

export const convertTime = (time: string) => {
  try {
    const formattedTime = removeSeconds(time);
    const utcTime = DateTime.fromFormat(formattedTime, 'HH:mm', {
      zone: 'UTC',
    });

    const cstTime = utcTime.setZone('America/Chicago');
    const watTime = utcTime.setZone('Africa/Lagos');

    return {
      cstTime: cstTime.toFormat('h:mma'),
      watTime: watTime.toFormat('h:mma'),
    };
  } catch (error: any) {
    console.log(error.message);
    return null;
  }
};

const removeSeconds = (time: string) => {
  const parts = time.split(':');
  const formattedTime = parts[0] + ':' + parts[1];

  return formattedTime;
};

export const convertToAbbreviation = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase().slice(0, 2);
};

export const convertToTitleCase = (text: string) => {
  if (!text) {
    return '';
  }
  const arr: string[] = text.split('');
  const first = arr.shift() as string;
  return first.toUpperCase() + arr.join('');
};

export const formatTitle = (text: string) => {
  if (text.includes('-')) {
    const arr = text.split('-');
    return `${convertToTitleCase(arr[0])} ${convertToTitleCase(arr[1])} ${convertToTitleCase(arr[2])}`;
  }
  return convertToTitleCase(text);
};

export const removeEmptyProperties = (obj: { [key: string]: any }) => {
  // Helper function to check if a value is empty
  const isEmpty = (value: any) =>
    value === '' || value === null || value === undefined;

  // Iterate over the object entries
  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        // If the value is an object, recursively remove empty properties
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          value = removeEmptyProperties(value);
        }

        // If the value is an array, handle the specific case for `questions`
        if (Array.isArray(value)) {
          if (key === 'questions') {
            value = value.filter(
              (question) => !isEmpty(question.question_text),
            );
          } else {
            value = value.filter((item) => !isEmpty(item));
          }
        }

        return [key, value];
      })
      .filter(([_, value]) => !isEmpty(value)), // Remove entries with empty values
  );
};

export const getDiscountedPrice = (
  discount_percentage: string,
  price: string,
) => {
  if (!Number(discount_percentage)) {
    return parseInt(price);
  }
  return (
    parseInt(price) - (parseInt(price) * parseInt(discount_percentage)) / 100
  );
};
