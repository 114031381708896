/* eslint-disable jsx-a11y/media-has-caption */
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { lockedAnimation } from '../../../../assets';
import { Spinner } from '../../../../components/Elements';
import { findFirstUnviewedResource } from '../../../../utils/misc';
import {
  useGetCourseDetails,
  useGetCourseProgress,
} from '../api/courseProgress';
import { Details } from '../components/Details';
import { Media } from '../components/Media';
import { ResourcesList } from '../components/ResourcesList';

export const CourseContent = () => {
  const { id } = useParams();
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(0);
  const [openResourceIndex, setOpenResourceIndex] = useState<number | null>(0);

  const [currentSectionIndex, setCurrentSectionIndex] = useState<number | null>(
    0,
  );

  const {
    data: courseProgress,
    isLoading: isCourseProgressLoading,
    isError: isCourseProgressError,
    error: courseProgressError,
  } = useGetCourseProgress(id!);

  const {
    data: courseDetails,
    isLoading: isCourseDetailsLoading,
    isError: isCourseDetailsError,
    error: courseDetailsError,
  } = useGetCourseDetails(id!);

  const navigate = useNavigate();

  const setDefaultOpenIndex = (sectionIndex: number, resourceIndex: number) => {
    setOpenSectionIndex(sectionIndex);
    setCurrentSectionIndex(sectionIndex);

    setOpenResourceIndex(resourceIndex);
  };

  useEffect(() => {
    if (
      courseProgress &&
      courseProgress.sections.length &&
      courseProgress.sections[courseProgress.sections.length - 1].resources
        .length
    ) {
      const result = findFirstUnviewedResource(courseProgress);
      setDefaultOpenIndex(result.sectionIndex!, result.resourceIndex!);
    }
  }, [courseProgress]);

  if (isCourseProgressLoading || isCourseDetailsLoading) {
    return (
      <div className='flex min-h-screen items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (
    isCourseProgressError ||
    isCourseDetailsError ||
    !courseProgress ||
    !courseDetails
  ) {
    return (
      <div className='flex min-h-screen items-center justify-center'>
        <div className='text-center'>
          {courseProgressError?.cause === 403 ? (
            <div className='flex flex-col items-center justify-center'>
              <img src={lockedAnimation} alt='locked gif' />
              <p>You do not have access to view this course.</p>
            </div>
          ) : (
            <p className='text-red-500'>
              {courseProgressError?.message ||
                courseDetailsError?.message ||
                'Invalid course ID. Please try again.'}
            </p>
          )}

          <button
            onClick={() => navigate('/student/my-courses')}
            className='mt-4 px-4 py-2 bg-primary hover:bg-secondary text-white rounded'
          >
            Go back to courses
          </button>
        </div>
      </div>
    );
  }

  const { course_title, sections: sectionsProgress } = courseProgress!;
  const { created_by, sections: sectionsDetails } = courseDetails!;

  const handlePrevious = () => {
    if (openResourceIndex! > 0) {
      setOpenResourceIndex((prev) => (prev ? prev - 1 : 0));
    } else if (openSectionIndex! > 0) {
      // Move to the last resource of the previous section
      setOpenSectionIndex((prev) => (prev ? prev - 1 : 0));
      setOpenResourceIndex(
        sectionsDetails[openSectionIndex! - 1].resources.length - 1,
      );
      setCurrentSectionIndex(openSectionIndex! - 1);
    }
  };

  const handleNext = () => {
    if (
      openResourceIndex! <
      sectionsDetails[openSectionIndex!].resources.length - 1
    ) {
      setOpenResourceIndex((prev) => prev! + 1);
    } else if (openSectionIndex! < sectionsDetails.length - 1) {
      // Move to the first resource of the next section
      setOpenSectionIndex((prev) => prev! + 1);
      setOpenResourceIndex(0);
      setCurrentSectionIndex(openSectionIndex! + 1);
    }
  };

  // Check if navigation buttons should be disabled
  const isPreviousDisabled = openSectionIndex === 0 && openResourceIndex === 0;

  const isNextDisabled =
    openSectionIndex === sectionsDetails.length - 1 &&
    openResourceIndex ===
      sectionsDetails[sectionsDetails.length - 1].resources.length - 1;

  return (
    <div className='flex flex-col w-full bg-white'>
      <div className='mb-4 mt-4 sm:mt-0'>
        <div className='flex items-center'>
          <button onClick={() => navigate(-1)}>
            <ArrowLeftIcon className='h-4 w-4 mr-2' />
          </button>

          <h3 className='font-semibold'>{course_title}</h3>
        </div>

        <div className='flex justify-between'>
          <h4 className='mt-2 font-thin'>
            By {created_by.full_name ?? 'Dr. Opeyemi Olaonipekun'}
          </h4>

          {courseDetails.certificate_url && (
            <a
              href={courseDetails.certificate_url}
              target='_blank'
              rel='noreferrer'
              className='flex font-semibold items-center justify-center bg-slate-700 hover:bg-secondary disabled:bg-slate-400 disabled:hover-bg-slate-500 text-sm h-[32px] min-w-[62px] px-4 rounded-xl text-white shadow-all-sides'
            >
              Get Certificate
            </a>
          )}
        </div>
      </div>

      <div className='flex flex-col xl:flex-row min-h-[80vh] mb-4'>
        {sectionsDetails.length ? (
          <>
            <div className='flex-1 lg:flex-[0.65] '>
              <div className='flex justify-between mb-2'>
                <button
                  onClick={handlePrevious}
                  disabled={isPreviousDisabled}
                  className={`px-2 py-1 h-[28px] w-[80px] text-sm bg-gray-300 text-gray-700 rounded-xl disabled:opacity-50`}
                >
                  Previous
                </button>

                <button
                  onClick={handleNext}
                  disabled={isNextDisabled}
                  className={`px-2 py-1 h-[28px] w-[80px] text-sm bg-orange-400 hover:bg-primary text-white rounded-xl disabled:opacity-50`}
                >
                  Next
                </button>
              </div>
              <Media
                openResourceDetails={
                  sectionsDetails[Number(currentSectionIndex)].resources[
                    Number(openResourceIndex)
                  ]
                }
              />
              <div className='min-h-[300px]'>
                <Details
                  openResourceDetails={
                    sectionsDetails[Number(currentSectionIndex)].resources[
                      Number(openResourceIndex)
                    ]
                  }
                  openResourceProgress={
                    sectionsProgress[Number(currentSectionIndex)].resources[
                      Number(openResourceIndex)
                    ]
                  }
                  courseDescription={courseDetails.description}
                  courseID={courseDetails.id}
                  sectionID={sectionsDetails[Number(currentSectionIndex)].id}
                />
              </div>
            </div>
            <div className='flex-1 lg:flex-[0.35]'>
              <ResourcesList
                sectionsDetails={sectionsDetails}
                sectionsProgress={sectionsProgress}
                openSectionIndex={openSectionIndex}
                openResourceIndex={openResourceIndex}
                setOpenSectionIndex={setOpenSectionIndex}
                setOpenResourceIndex={setOpenResourceIndex}
                currentSectionIndex={currentSectionIndex}
                setCurrentSectionIndex={setCurrentSectionIndex}
              />
            </div>
          </>
        ) : (
          'No content has been uploaded for this course yet.'
        )}
      </div>
    </div>
  );
};
