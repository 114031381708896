import { UserIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { logo, menu, close } from '../../assets';
import { websiteLinks } from '../../constants/navlinks';
import storage from '../../utils/storage';
import { Link } from '../Elements';

const menuItemStyles = {
  fontSize: '14px',
  cursor: 'default',
};

export const Navbar: React.FC<{ activePage?: string }> = ({ activePage }) => {
  const navigate = useNavigate();
  const token = storage.getToken();
  const loggedInUser = storage.getUser();

  const [toggle, setToggle] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    storage.clearToken();
    storage.clearUser();
    navigate('/');
  };

  return (
    <nav
      className={`w-full flex py-2 px-8 items-center navbar bg-white h-[60px]`}
    >
      <div className='flex items-center'>
        <Link to='/'>
          <img src={logo} alt='logo' className='main-logo sm:w-[128px]' />
        </Link>
      </div>

      <div className='flex w-full'>
        <ul className='items-center justify-center flex-1 hidden list-none sm:flex'>
          {websiteLinks.map((l, i) => (
            <li
              key={l.pathname}
              className={`font-poppins font-normal cursor-pointer text-[14px] text-black xl:ml-8 ${
                activePage === l.pathname ? ' text-primary' : ''
              } ${i === websiteLinks.length - 1 ? 'mr-0' : 'mr-8 '}`}
            >
              <Link
                data-cy={`navlink-${l.pathname}`}
                className='hover:text-primary'
                to={`/${l.pathname}`}
              >
                {l.title}
              </Link>
            </li>
          ))}
        </ul>

        {token ? (
          <div className='items-center justify-end hidden list-none sm:flex flex-2'>
            <span
              className={`font-poppins font-normal cursor-pointer text-[14px] mr-4`}
            >
              Welcome, {loggedInUser.first_name}
            </span>
            <span
              className={`font-poppins font-normal cursor-pointer text-[14px] mr-4`}
            >
              <Link
                className={`hover:text-white hover:bg-primary font-semibold items-center flex text-white bg-secondary py-1 px-2 rounded-md`}
                to={`${loggedInUser.role === 'ADMIN' ? '/admin' : '/student'}`}
              >
                <RectangleGroupIcon className='w-4 h-4 mr-1' />
                Go to Dashboard
              </Link>
            </span>

            <div>
              <IconButton
                onClick={handleMenuOpen}
                color='inherit'
                aria-label='Open user menu'
                style={{
                  border: loggedInUser.image?.presigned_url
                    ? '1px solid #dddddd'
                    : '1px solid #bbbbbb',
                  padding: loggedInUser.image?.presigned_url ? '2px' : '8px',
                }}
              >
                {loggedInUser.image?.presigned_url ? (
                  <img
                    src={loggedInUser.image.presigned_url}
                    className='w-8 h-8 rounded-full'
                    alt='profile'
                  />
                ) : (
                  <UserIcon className='w-[18px] rounded-full text-indigo' />
                )}
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className='mt-12'
              >
                <MenuItem sx={menuItemStyles}>
                  {loggedInUser.first_name} {loggedInUser.last_name}
                </MenuItem>
                <MenuItem sx={menuItemStyles}>{loggedInUser.email}</MenuItem>
                <MenuItem
                  sx={{
                    ...menuItemStyles,
                    borderBottom: '1px solid #ccc',
                  }}
                >
                  {loggedInUser.is_staff ? 'Admin' : 'Student'}
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: menuItemStyles.fontSize,
                    ':hover': {
                      color: '#FD8323',
                    },
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </div>
        ) : (
          <ul className='items-center justify-end hidden list-none sm:flex flex-2'>
            <li>
              <Link
                data-cy='navlink-signin'
                className={`hover:text-white py-1 px-2 font-poppins font-normal cursor-pointer text-[14px] bg-primary text-white  rounded hover:bg-secondary`}
                to={`/auth/login`}
              >
                Sign In
              </Link>
            </li>
          </ul>
        )}
      </div>

      <div className='flex items-center justify-end flex-1 sm:hidden'>
        <button
          className='w-[28px] h-[28px] object-contain outline-none'
          onClick={() => setToggle((prev) => !prev)}
        >
          <img src={toggle ? close : menu} alt='menu' />
        </button>

        <div
          className={`${
            toggle ? 'flex' : 'hidden'
          } p-6 bg-slate-300 absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar z-50`}
        >
          <ul className='flex flex-col items-center justify-end flex-1 list-none'>
            {token && (
              <li
                key='dashboard'
                className={`font-poppins font-normal cursor-pointer text-[16px] text-black mb-4 ${
                  activePage === 'dashboard'
                    ? 'border-b-[2px] mt-[6px] pb-1 border-primary text-indigo'
                    : ''
                }`}
              >
                <Link
                  data-cy='navlink-dashboard'
                  onClick={() => {
                    setToggle((prev) => !prev);
                  }}
                  className='hover:text-primary'
                  to={`${loggedInUser.role === 'ADMIN' ? '/admin' : '/student'}`}
                >
                  Dashboard
                </Link>
              </li>
            )}
            {websiteLinks.map((l, i) => (
              <li
                key={l.pathname}
                className={`font-poppins font-normal cursor-pointer text-[16px] text-black ${
                  activePage === l.pathname
                    ? 'border-b-[2px] mt-[6px] pb-1 border-primary text-indigo'
                    : ''
                } ${i === websiteLinks.length - 1 ? 'mb-0' : 'mb-4'}`}
              >
                <Link
                  onClick={() => {
                    setToggle((prev) => !prev);
                  }}
                  className='hover:text-primary'
                  to={`/${l.pathname}`}
                >
                  {l.title}
                </Link>
              </li>
            ))}

            {token ? (
              <li
                className={`font-poppins font-normal cursor-pointer text-[16px] text-black  mt-4`}
              >
                <Link
                  onClick={() => {
                    setToggle((prev) => !prev);
                    storage.clearToken();
                    storage.clearUser();
                    window.location.reload();
                  }}
                  className={`hover:text-primary`}
                  to={`/`}
                >
                  Sign Out
                </Link>
              </li>
            ) : (
              <>
                <li
                  className={`font-poppins font-normal cursor-pointer text-[16px] text-black mt-4 mb-4`}
                >
                  <Link className={`hover:text-primary`} to={`/auth/login`}>
                    Sign In
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
