import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';
import { TypeOf, object, string } from 'zod';

import { Button } from '../../../../components/Elements';
import { ErrorProps } from '../../../../types';
import getErrorMsg from '../../../../utils/getErrorMsg';
import { notifySuccess } from '../../../../utils/notifications';
import styles from '../../../../utils/styles';
import { useGetLiveSessions, useUnregisterStudentFromCourse } from '../api';
import { Student } from '../routes/Students';

export const editStudentSchema = object({
  full_name: string(),
  email: string(),
  live_session_id: string().min(1, 'Live session is required'),
});

export type EditStudentInput = TypeOf<typeof editStudentSchema>;

export const Unregister: React.FC<{
  data: Student;
  closeModal: any;
}> = ({ data, closeModal }) => {
  const { data: liveSessions } = useGetLiveSessions();
  const {
    mutate: unregisterStudent,
    isPending: isUnregisterStudentPending,
    isSuccess: isUnregisterSuccess,
    reset: resetManualUnregister,
    error: unregisterError,
  } = useUnregisterStudentFromCourse();

  if (isUnregisterSuccess) {
    notifySuccess('Un-register success', false);
    resetManualUnregister();
    closeModal();
  }

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<EditStudentInput>({
    resolver: zodResolver(editStudentSchema),
    defaultValues: {
      full_name: `${data.first_name} ${data.last_name}`,
      email: data.email,
    },
  });

  const onSubmit = ({ email, live_session_id }: EditStudentInput) => {
    unregisterStudent({ email, live_session_id });
  };

  return (
    <div className='bg-white absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 w-[90%] lg:w-[600px] min-h-[300px] shadow-[24px] p-4 rounded-lg'>
      <button
        onClick={closeModal}
        className='shadow-all-sides absolute -top-4 -right-4 z-50 bg-white p-2 rounded-full text-gray-500 hover:text-gray-700 cursor-pointer'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-5 w-5'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M6 18L18 6M6 6l12 12'
          />
        </svg>
      </button>

      <div>
        <h3 className='text-lg font-semibold'>
          Manually Un-register Student from Course
        </h3>

        {unregisterError && (
          <p className='text-red-500 text-sm font-semibold'>
            {getErrorMsg(unregisterError as unknown as ErrorProps)}
          </p>
        )}

        <form className='max-w-[600px] mt-4' onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formControl}>
            <label htmlFor='full_name' className={styles.label}>
              Full Name
            </label>
            <input
              {...register('full_name')}
              placeholder='full_name'
              type='text'
              className={`${styles.input} bg-gray-100 cursor-not-allowed`}
              data-cy='full_name'
              readOnly={true}
              disabled={true}
            />
            <p className={styles.error}>
              {errors.full_name?.message as string}
            </p>
          </div>

          <div className={styles.formControl}>
            <label htmlFor='email' className={styles.label}>
              Email
            </label>
            <input
              {...register('email')}
              placeholder='email'
              type='email'
              className={`${styles.input} bg-gray-100 cursor-not-allowed`}
              data-cy='email'
              readOnly={true}
              disabled={true}
            />
            <p className={styles.error}>{errors.email?.message as string}</p>
          </div>

          <div className={styles.formControl}>
            <label htmlFor='phone' className={styles.label}>
              Course
            </label>

            <Controller
              name='live_session_id'
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <Select
                    data-cy='live_session_id'
                    classNamePrefix='live_session_id'
                    placeholder='Select Course'
                    onChange={(
                      option: SingleValue<{
                        title: string;
                        id: string;
                        slug: string;
                      }>,
                    ) => {
                      onChange(String(option?.id));
                    }}
                    getOptionLabel={(item) => item.slug}
                    getOptionValue={(item) => item.id}
                    options={liveSessions?.results ?? []}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isDisabled ? '#e5e7eb' : '#fff',
                        borderColor: state.isDisabled ? '#d1d5db' : '#ccc',
                        cursor: state.isDisabled ? 'not-allowed' : 'default',
                      }),
                    }}
                  />
                );
              }}
            />
            <p className={styles.error}>
              {errors.live_session_id?.message as string}
            </p>
          </div>

          <div className='mt-8 flex justify-start'>
            <Button
              data-cy='submit'
              type='submit'
              variant='primary'
              isLoading={isUnregisterStudentPending}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
