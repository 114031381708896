import { GoogleLogin } from '@react-oauth/google';

export const GoogleSignInBtn = ({ googleLogin }: any) => {
  const handleGoogleSignInSuccess = async (resp: any) => {
    //console.log(resp);
    await googleLogin(resp.credential);
  };

  const handleGoogleSigninError = () => {
    console.log('Google signin failed');
  };

  return (
    <div
      id='googleSignIn'
      className='flex flex-1 items-center py-2 justify-center'
    >
      <GoogleLogin
        onSuccess={handleGoogleSignInSuccess}
        onError={handleGoogleSigninError}
        useOneTap={true}
      />
    </div>
  );
};
