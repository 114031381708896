import { useMutation, useQuery } from '@tanstack/react-query';

import { WEB_URL } from '../../../config';
import { axios } from '../../../lib/axios';
import storage from '../../../utils/storage';
import { RegistrationPayload } from '../types';

export const getUpcomingLiveSessions = async () => {
  const user = storage.getUser();
  const response = await axios.get(
    `/sessions/live/?status=upcoming&include_expired=${Boolean(user?.is_staff)}`,
  );

  return response.data;
};

const getPreviousLiveSessions = async () => {
  const user = storage.getUser();
  const response = await axios.get(
    `/sessions/live/?status=completed&include_expired=${Boolean(user?.is_staff)}`,
  );

  return response.data;
};

const getUpcomingLiveSession = async (id: string) => {
  const user = storage.getUser();

  const response = await axios.get(
    `/sessions/live/${id}?include_expired=${Boolean(user?.is_staff)}`,
  );

  return response.data;
};

const generatePaymentURL = async (payload: RegistrationPayload) => {
  const baseURL = new URL(WEB_URL.trim());
  const callback_url = new URL(
    `/registration/${payload.slug!.trim()}`,
    baseURL,
  );

  const response = await axios.post('/liveregister/', {
    ...payload,
    callback_url,
  });

  return response.data;
};

export const verifyPayment = async (transRef: string) => {
  if (transRef) {
    const response = await axios.get(
      `/liveregister/payment-status/${transRef}/`,
    );

    return response.data;
  } else {
    return {
      message: 'No transaction reference provided',
    };
  }
};

export const useGetUpcomingLiveSessions = () => {
  return useQuery({
    queryFn: getUpcomingLiveSessions,
    queryKey: ['upcoming-live-sessions'],
    staleTime: 24 * 60 * 60 * 1000,
    select: (data) => {
      return data?.data; //?.results;
    },
  });
};

export const useGetPreviousLiveSessions = () => {
  return useQuery({
    queryFn: getPreviousLiveSessions,
    queryKey: ['previous-live-sessions'],
    staleTime: 24 * 60 * 60 * 1000,
    select: (data) => {
      return data?.data?.results;
    },
  });
};

export const useGetUpcomingLiveSession = (id: string) => {
  return useQuery({
    queryKey: ['upcoming-live-session', id],
    queryFn: () => getUpcomingLiveSession(id),
    select: (data) => {
      return data?.data;
    },
  });
};

export const useGeneratePaymentURL = () => {
  return useMutation({
    mutationFn: generatePaymentURL,
  });
};

export const useVerifyPayment = (transRef: string) => {
  return useQuery({
    queryKey: ['payment-verification', transRef],
    queryFn: () => verifyPayment(transRef),
    select: (data) => {
      return data?.data;
    },
  });
};
