import { useLocation } from 'react-router-dom';

import { Footer } from './Footer';
import { Navbar } from './Navbar';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const location = useLocation();
  const page: string = location.pathname.split('/')[1];

  return (
    <div className='2xl:container 2xl:mx-auto min-h-screen flex flex-col'>
      <Navbar activePage={page} />
      <div className='2xl:flex-1'>{children}</div>
      <Footer />
    </div>
  );
};
