import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';
import { ErrorProps } from '../../../../types';
import getErrorMsg from '../../../../utils/getErrorMsg';
import { notifyError } from '../../../../utils/notifications';
import { getUpcomingLiveSessions } from '../../../liveworkshops/api';
import { ScheduleLiveSessionInput } from '../types';

const updateUpcomingLiveSession = async (
  payload: ScheduleLiveSessionInput & { id: string },
) => {
  const resp = await axios.patch(
    `/sessions/live/${payload.id}/?include_expired=${true}`,
    payload,
  );

  return resp.data;
};

export const useUpdateUpcomingLiveSession = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUpcomingLiveSession,
    onSuccess: async (data) => {
      try {
        if (data?.data?.status === 'upcoming') {
          const oldQueryData: any = queryClient.getQueryData([
            'upcoming-live-sessions',
          ]);

          let update;

          if (!oldQueryData) {
            const apiResp = await getUpcomingLiveSessions();

            if (apiResp.status === 200) {
              update = {
                ...apiResp,
                data: [
                  data.data,
                  ...apiResp.results.data.filter(
                    (item: any) => item.id !== data.data.id,
                  ),
                ],
              };
            }
          } else {
            update = {
              ...oldQueryData,
              data: {
                ...oldQueryData.data,

                results: [
                  data.data,
                  ...oldQueryData.data.results.filter(
                    (item: any) => item.id !== data.data.id,
                  ),
                ],
              },
            };
          }

          queryClient.setQueryData(['upcoming-live-sessions'], update);
        }

        queryClient.invalidateQueries({
          queryKey: [`live-session_${data.data.id}`],
        });
      } catch (error) {
        console.log(error);
      }
    },
    onError: (error) => {
      notifyError(getErrorMsg(error as unknown as ErrorProps), false);
    },
  });
};
